/* eslint-disable import/no-anonymous-default-export */
const route = () => {
    const v = process.env.REACT_APP_POINT
    switch (v) {
      case 'PROD':
        return process.env.REACT_APP_API_PROD
      case 'PRE_PROD':
        return process.env.REACT_APP_API_PRE_PROD
      default:
        return process.env.REACT_APP_API_TEST
    }
  }
  
  const baseApi = {
    point: route()
  }

  export default {
    viewer: baseApi.point + 'orden/por_detail'
  }