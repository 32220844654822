import axios from 'axios'
import Api from './Api'


const detailImage = (data, callback) => {
  try {
    axios
      .post(Api.viewer, data)
      .then(res => {

        callback({ result: res.data, error: false })
      })
      .catch(e => {
        callback({ result: null, error: true })
      })
  } catch (err) {
    callback({ result: null, error: true })
  }
}


const ApiRx = {

  detailImage

}

export default ApiRx
