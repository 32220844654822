// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

// ** Icon Imports

import { Grid } from '@mui/material'
import Icon from '../../core/components/icon'

const DialogViewImage = props => {
  // ** State
  const { show, setShowViewImage, file } = props
  const renderFilePreview = file => {
    const url = file?.enlace_archivo 
    return (
      <img
        src={url}
        alt={file?.name}
        style={{ width: '100%', height: 'auto', maxHeight: '85vh',objectFit: 'cover',  maxWidth: '100%'  }}
      />
    )
  }

  return (
    <div>
      <Button variant='outlined' onClick={() => setShowViewImage(false)}>
        Open dialog
      </Button>
      <Dialog fullScreen onClose={() => setShowViewImage(false)} aria-labelledby='full-screen-dialog-title' open={show}>
        <DialogTitle id='full-screen-dialog-title'>
          <Typography variant='h6' component='span'>
            Viewer
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setShowViewImage(false)}
            sx={{ top: 8, right: 10, position: 'absolute', color: 'grey.500' }}
          >
            <Icon icon='mdi:close' />
          </IconButton>
        </DialogTitle>
        {file && (
          <DialogContent>
            <Grid
              style={{
                background: 'black',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '85vh',
                flexDirection: 'column', alignItems: 'center'
              }}
            >
              {renderFilePreview(file)}
            </Grid>

            <Typography variant='body1' style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '0px' }}>
              {file?.name}{' '}
            </Typography>
          </DialogContent>
        )}
        <DialogActions className='dialog-actions-dense'>
          <Button onClick={() => setShowViewImage(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogViewImage
