import Grid from '@mui/material/Grid'

const Index = () =>{

    return <Grid item>
             <Grid item sx={{ mt: 10, mb: 0 }} xs='auto' align='center'>
        <img alt='di_logo_header' src={'/images/isologos/di_ic.png'} height={80} />
      </Grid>
      <Grid item sx={{ mt: 5, mb: 0 }} xs='auto' align='center'>
        <img alt='di_logo_header' src={'/images/isologos/di_logo_header.png'} height={80} />
      </Grid>
    </Grid>

}

export default Index