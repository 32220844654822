import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import './App.css';
import Viewer from "./page/viewer"
import Index from "./page/index"

function App() {
  return (
   
    <div className="App">
       <head>
       <meta http-equiv='Content-Security-Policy' content='upgrade-insecure-requests' />
       </head>
      <Router>
      
        <Routes>
        <Route exact path="/" element={<Index />} />
          <Route exact path="/viewer" element={<Viewer />} />
        </Routes>


      </Router>
    </div>
  );
}

export default App;
